import React, { useEffect, useState } from 'react'
import services from './apiServices'
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function UserApprovalAndAdding() {
    const [uname, setuname] = useState()
    const [d, setd] = useState({ a_ids: [], a_name: [], l_ids: [], diaplay_name: [], status: [], laids: [], luids: [], udisp: [], afnames: [] })
    const [approvaldata, setapprovaldata] = useState({ a_ids: [], a_name: [], l_ids: [], diaplay_name: [], status: [] })

    useEffect(() => {
        const get = async () => {
            const data = await services.getallnonapprovedusers()
            if (data !== false) {
                // const setstatusfalse=data.a_ids.map(users=>({...users,checked:false}))
                // console.log(data,"This is the data")
                setd(data)
            }
            else {
                setuname('')
            }
        }
        get()
    }, [])
    if (uname === '') {
        localStorage.clear()
        window.location.replace('/')
    }
    else {

        // console.log(d)
        const handleChange = (index) => (e) => {
            const ndata = { ...d }
            ndata.status[index] = e.target.checked
            const approvedData = {
                a_ids: e.target.checked ? [...approvaldata.a_ids, ndata.a_ids[index]] : approvaldata.a_ids.filter((id) => id !== ndata.a_ids[index]),
                a_name: e.target.checked ? [...approvaldata.a_name, ndata.a_name[index]] : approvaldata.a_name.filter((name) => name !== ndata.a_name[index]),
                l_ids: e.target.checked ? [...approvaldata.l_ids, ndata.l_ids[index]] : approvaldata.l_ids.filter((id) => id !== ndata.l_ids[index]),
                diaplay_name: e.target.checked ? [...approvaldata.diaplay_name, ndata.diaplay_name[index]] : approvaldata.diaplay_name.filter((name) => name !== ndata.diaplay_name[index]),
                status: e.target.checked ? [...approvaldata.status, ndata.status[index]] : approvaldata.status.filter((stat) => stat !== ndata.status[index])
            };
            setapprovaldata(approvedData);
        }

        const handleClick = async (e) => {
            e.preventDefault()
            const res = await services.sendApprovalData(approvaldata)
            console.log(res)
            if (res == true) {
                alert("User Approved")
                window.location.reload(false)
            }
        }

        const handleRejectClick = async (e) => {
            e.preventDefault()
            const res = await services.sendRejectionData(approvaldata)
            console.log(res)
            if (res == true) {
                alert("User Rejected")
                window.location.reload(false)
            }
        }

        return (
            <>
                <div className='full'>
                    <div className="heading">
                        <h1 >Approval</h1>
                        <span style={{ color: "#FFBB05" }}>User Approval and Rejection</span><br />&nbsp;
                    </div>
                    <div className="cd-half-width fourth-slide">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content3 fourth-content">
                                        <table className="table table-hover table-striped  table-bordered">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">#</th> */}
                                                    <th scope="col">Company Name</th>
                                                    <th scope="col">Login id</th>
                                                    <th scope="col">Names</th>
                                                    <th scope="col">Select</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {d.a_ids.map((id, index) => (
                                                    <tr>
                                                        <td>{d.a_name[index]}</td>
                                                        <td>{d.l_ids[index]}</td>
                                                        <td>{d.diaplay_name[index]}</td>
                                                        <td><Checkbox onChange={handleChange(index)}
                                                            {...label}
                                                            // defaultChecked
                                                            checked={d.status[index] || false}
                                                            sx={{
                                                                // color: " #ffbb05",
                                                                '&.Mui-checked': {
                                                                    color: "#ffbb05",
                                                                },
                                                            }}
                                                        /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <button type="" onClick={handleRejectClick} id="form-submit" className="btn" >&nbsp;&nbsp;<b>Reject</b>&nbsp;&nbsp;</button>
                                        <button type="" onClick={handleClick} style={{ marginLeft: '10px' }} id="form-submit" className="btn" >&nbsp;&nbsp;<b>Approve</b>&nbsp;&nbsp;</button>


                                    </div>
                                </div>
                            </div>

                            <div className="col custcol">
                                <div className="row-md-4">
                                    <div className="heading2">
                                        <h1 >USer List</h1>
                                        <span>All the existing users</span><br />&nbsp;
                                    </div>
                                </div>
                                <div className='row-md-8 custpad'>
                                    <div className="content2 fourth-content">
                                        <table className="table table-hover table-striped  table-bordered">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">#</th> */}
                                                    <th scope="col">Company Name</th>
                                                    <th scope="col">Login id</th>
                                                    <th scope="col">Names</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {d.laids.map((id, index) => (
                                                    <tr>
                                                        <td>{d.afnames[index]}</td>
                                                        <td>{d.luids[index]}</td>
                                                        <td>{d.udisp[index]}</td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
