import React from 'react'

export default function Footer() {
  return (
    <>

<footer className='footer'>
    <p>Copyright &copy;NT Valuation                    
      |Developed and Designed by <a href="/" target="_parent"><em>Ardi Bi Systems</em></a></p>
  </footer>

    </>
  )
}
