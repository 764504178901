import './App.css';
import AboutUs from './Components/AboutUs';
import Navbar from './Components/Navbar';
import Usage from './Components/Usage';
import Users from './Components/Users';
import Welcome from './Components/Welcome';
import Footer from './Components/Footer';
import Recharge from './Components/Recharge';
import Login from './Components/Login'
import Logout from './Components/Logout'


import {
  BrowserRouter as Router,
  // Switch,
  Route,
  Routes,
  // Link
} from "react-router-dom";
import './Css/rdbs.css'
import './Css/rdbsmedia.css'
import './Css/templatemo-style.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap"
import Readme from './Components/Readme';
import Header from './Components/Header';
import { useEffect, useState } from 'react';
import UserApprovalAndAdding from './Components/UserApprovalAndAdding';







function App() {

  const [isloggedin, setisloggedin] = useState(false)
  const [sessioncheck, setsessioncheck] = useState(false)
  const [usertype, setusertype] = useState('')

  useEffect(()=>{
    const check=async()=>{
      const loggedin=localStorage.getItem('user')
      const utype=localStorage.getItem('utype')
      if(loggedin){
        setisloggedin(true)
        setusertype(utype)
      }
      else{
        setisloggedin(false)
      }
    }

    check()
  })


  const checklog = async (check) => {
    // console.log(check)
    setisloggedin(check)
    if (isloggedin) {
      await setsessioncheck(true)
    }
  }
  // console.log(sessioncheck)

  const usert = (type) => {
    // console.log(type, "ghvchjvacashvhjsv")
    setusertype(type)

  }


  return (
    <>

      <Router>
        {isloggedin ? (
          
            <>
              <Navbar typeofuser={usertype} />
              <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/approval" element={<UserApprovalAndAdding />} />
                <Route path="/usage" element={<Usage />} />
                <Route path="/recharge" element={<Recharge />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/logout" element={<Logout/>} />
              </Routes>
              <Footer />
            </>
          
          
          ) : (
          <>
            <Header />
            <Routes>
              <Route path="/users" element={<Users />} />
              <Route path="/readme" element={<Readme />} />
              <Route path="/" element={<Login logincheck={checklog} u_type={usert} />} />
            </Routes>
            <Footer />
          </>
        )}
      </Router >

      {/* <Footer/>
    <Header/>
    <Recharge/> */}

      {/* <Login/>  */}
      {/* <Users/> */}
      {/* <Readme/> */}

    </>
  );
}

export default App;
