import React, { useEffect, useState } from 'react'
import qr from '../Img/RDBS_QR_Cd_v2.jpg'
import services from './apiServices'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function Recharge() {

  const [rechargedetails, setrechargedetails] = useState({
    bal_amt: 0,
    recharge: 0,
    o_name: '',
    contact_per_name: '',
    upi: ''
  })

  const [getsessiondata, setsessiondata] = useState({})
  const [uname, setuname] = useState()
  // window.location.reload();

  useEffect(() => {
    const fetch = async () => {
      const data = await services.getrechargesessiondata()
      if (data !== false) {
        setsessiondata(data)
        // console.log(data)
        setrechargedetails(prevstate => ({
          ...prevstate,
          bal_amt: data.bal_amt,
          o_name: data.co_name,
          contact_per_name: data.disp_name
        }))
      }
      else {
        setuname('')
      }
    }
    fetch()

  }, [])
  if (uname === '') {
    localStorage.clear()
    window.location.replace('/')
  }
  else {

    // console.log(getsessiondata)

    const handleChange = (e) => {
      setrechargedetails({ ...rechargedetails, [e.target.name]: e.target.value })
    }
    // console.log(rechargedetails)

    const handleClick = async (e) => {
      e.preventDefault()
      if (window.confirm("Confirm Recharge...") == true) {

        // alert("Confirm Recharge?")
        const response = await services.sendrechargedata(rechargedetails)
        console.log(response)
        window.location.reload(false)
      }
      else {
        window.location.reload(false)

      }
    }




    return (
      <>
        <div className='full'>
          <div className="heading">
            <h1 >Recharge</h1>
            <span style={{ color: "#FFBB05" }}>Here you can recharge your balance</span><br />&nbsp;
          </div>
          <div className="cd-half-width fourth-slide">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="content fourth-content">
                    <h4>User Information</h4>


                    <form id="contact" action="" method="post">
                      <div className="row">
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Organisation Name</label>
                            <TextField style={{ width: '100%' }} id="outlined-basic" className='size' variant="outlined" size='small' color='warning' name="o_name" disabled required readOnly value={getsessiondata.co_name} onChange={handleChange} />

                            {/* <input name="o_name" readOnly value={getsessiondata.co_name} onChange={handleChange} type="text" className="form-control" id="name" placeholder="Organisation Name" required/> */}
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Contact Person Name</label>
                            <TextField style={{ width: '100%' }} id="outlined-basic" className='size' variant="outlined" size='small' color='warning' name="contact_per_name" disabled readOnly value={getsessiondata.disp_name} onChange={handleChange} />
                            {/* <input name="contact_per_name" readOnly value={getsessiondata.disp_name} onChange={handleChange} type="text" className="form-control" id="email" placeholder="Contact Person Name" required=""/> */}
                          </fieldset>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <h4>Payment Information</h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1">
                        </div>
                        <div className="col-md-3">
                          <img src={qr} className='qrsize' />
                        </div>
                        <div className="col-md-1">
                        </div>

                        <div className="col-md-7">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Current Balance</label>
                            <TextField style={{ width: '100%' }} id="outlined-basic" className='size' variant="outlined" size='small' color='warning' name="bal_amt" readOnly value={getsessiondata.bal_amt} onChange={handleChange} />
                            {/* <input name="bal_amt" readOnly value={getsessiondata.bal_amt} onChange={handleChange} type="number" className="form-control" id="name" placeholder="Current Balance" required=""/> */}
                          </fieldset>
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Recharge Amount</label>
                            <TextField style={{ width: '100%' }} id="outlined-basic" className='size' variant="outlined" size='small' color='warning' name="recharge" value={rechargedetails.recharge} onChange={handleChange} type="number" />

                            {/* <input name="recharge" value={rechargedetails.recharge} onChange={handleChange} type="number" className="form-control" id="email" placeholder="Recharged Amount" required=""/> */}
                          </fieldset>

                          <fieldset className="fld_area">
                            <label className="fld_lbl">UPI Id:</label>
                            <TextField style={{ width: '100%' }} id="outlined-basic" className='size' variant="outlined" size='small' color='warning' name="upi" value={rechargedetails.upi} onChange={handleChange} placeholder="Enter the UPI Id" />
                            {/* <input name="upi" value={rechargedetails.upi} onChange={handleChange} type="text" className="form-control" id="email" placeholder="Enter the UPI Id" required=""/> */}
                          </fieldset>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area">
                          <button type="submit" id="form-submit" onClick={handleClick} className="btn">&nbsp;&nbsp;&nbsp;&nbsp;<b>Recharge</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>
                        </fieldset>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>

                    </form>


                    <div className="row">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </>
    )
  }
}
