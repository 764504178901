import React from 'react'
// import MainComponent from './Components/MainComponent';
import Footer from './Footer'
import Header from './Header'
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import services from './apiServices';
import Welcome from './Welcome';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export default function Login({ logincheck, u_type }) {


    // const [isloggedin,setisloggedin]=useState(false)
    const [assoid, setassoid] = useState([])

    useEffect(() => {
        const fetch = async () => {
            const ids = await services.getassociateidlogin()
            setassoid(ids)
            // console.log(assoid)
        }
        fetch()
    }, [])

    const [logincreds, setlogincreds] = useState({
        associateid: '',
        loginid: '',
        password: ''
    })

    // console.log(assoid)

    const handleonchange = (e) => {
        setlogincreds({ ...logincreds, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(logincreds)
        const res = await services.sendLoginData(logincreds)
        // console.log(res.entitykey)
        
        if (res === 'Not Approved') {
            // console.log(res)
            // setisloggedin(false)
            alert("You are not yet approved by the admin...")
            logincheck(false)
            window.location.reload(false)
            
        }
        else if(res === 'Rejected'){
            alert("You are rejected by the admin...")
            logincheck(false)
            window.location.reload(false)
        }
        else if (res.uname === logincreds.loginid) {
            // console.log(res.uname)
            // console.log(res)
            // setisloggedin(true)
            localStorage.setItem('user',logincreds.loginid)
            localStorage.setItem('utype',res.usert)
            // alert("You have successfully logged in")
            logincheck(true)
            u_type(res.usert)
            // window.location.href = `/maincomponent`;
        }
        else if (res == false) {
            // console.log(res)
            // setisloggedin(false)
            alert("Wrong Username or password")
            logincheck(false)
            window.location.reload(false)
        }
    }

    return (
        <>

            {/* <Header /> */}
            <section className="cd-hero">
                <ul className="cd-hero-slider">
                    <li className="selected">
                        <div className="heading">
                            <h1 >Login</h1>
                            <span>Welcome To Property Valuation Calculator Application</span>
                            <p>&nbsp;</p>
                        </div>
                        
                        <div className="cd-full-width first-slide cd-hero-form">
                            <div className="container">
                                <div className="row row-custom">

                                    <div className="col-md-8 row-custom">
                                        <div className="content login-content login-custom">
                                            <h4 className='custom-label-login'>Login</h4>
                                            <form name="frm_login" id="frm_login" action="" className="form-custom" onSubmit={handleSubmit}>
                                                <div className="row size">
                                                    {/* <div className="col-md-4">
                                                        <fieldset className="fld_area">
                                                            <label className=""  >Associate ID :</label>
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-md-8"> */}
                                                    {/* <fieldset className="fld_area">
                                                            <select className="form-control" onChange={handleonchange} value={logincreds.associateid} name="associateid" id="login_org_id">
                                                                <option value="0">Select Option</option>
                                                                {assoid.map(id => (
                                                                    <option value={id.AFILT_KY}>{id.AFL_NAME}</option>
                                                                ))}
                                                            </select>

                                                        </fieldset> */}
                                                    <div className="col-md-4 custom-label">
                                                        <label className=""  >Associate ID :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div>
                                                            <TextField
                                                                id="outlined-select-currency"
                                                                select
                                                                className='size'
                                                                style={{ width: '100%' }}
                                                                label="Select Company Name"
                                                                value={logincreds.associateid}
                                                                onChange={handleonchange}
                                                                color="warning"
                                                                size='small'
                                                                name='associateid'
                                                            >
                                                                {assoid.map((option) => (
                                                                    <MenuItem key={option.AFILT_KY} value={option.AFILT_KY}>
                                                                        {option.AFL_NAME}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>

                                                        </div>
                                                    </div>

                                                    {/* </div> */}
                                                </div>

                                                <div className="row size">
                                                    {/* <div className="col-md-4">
                                                        <fieldset className="fld_area">
                                                            <label className=""  >Login ID :</label>
                                                        </fieldset>
                                                    </div> */}
                                                    {/* <div className="col-md-12"> */}
                                                    {/* <fieldset className="fld_area"> */}
                                                    {/* <TextField id="outlined-basic" width="50%" helperText="Enter your login id" className='size' label="Login Id" variant="outlined" size='small' color='warning' name="loginid" onChange={handleonchange} value={logincreds.loginid}/> */}
                                                    {/* <input name="loginid" onChange={handleonchange} type="text" className="form-control" id="val_usr" placeholder="Login ID" value={logincreds.loginid} /> */}
                                                    {/* </fieldset> */}
                                                    {/* </div> */}
                                                    <div className="col-md-4 custom-label">
                                                        <label className=""  >Login ID :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                    <TextField style={{width:'100%'}} id="outlined-basic" className='size' label="Login Id" variant="outlined" size='small' color='warning' name="loginid" onChange={handleonchange} value={logincreds.loginid} />
                                                    </div>
                                                </div>
                                                <div className="row size">

                                                    {/* <fieldset className="fld_area">
                                                            <input name="password" onChange={handleonchange} type="password" className="form-control" id="val_pwd" value={logincreds.password} placeholder="Password" />
                                                        </fieldset> */}
                                                    <div className="col-md-4 custom-label">
                                                        <label className=""  >Password :</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                    <TextField style={{width:'100%'}} id="outlined-basic" name="password" onChange={handleonchange} type="password" value={logincreds.password} label="Password" variant="outlined" size='small' color='warning' />
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-md-4">
                                                        <fieldset className="fld_area">
                                                            <label className="" style={{ float: "right" }}></label>
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <fieldset className="fld_area">
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <hr />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="primary-button custom-button">


                                                        {/* <button type="" id="form-submit"  className="btn" >&nbsp;&nbsp;<b>Cancel</b>&nbsp;&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp; */}
                                                        <button type="" id="form-submit" className="btn" >&nbsp;&nbsp;<b>Login</b>&nbsp;&nbsp;</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                        <Link type="" to="/readme" className="btn btn-style" >&nbsp;&nbsp;<b>Register</b>&nbsp;&nbsp;</Link>

                                                        {/* <button type="" id="form-submit"  className="btn" >&nbsp;&nbsp;<Link to="/users"><b>Register</b></Link>&nbsp;&nbsp;</button> */}


                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-md-12">
                                                        <hr />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <fieldset className="fld_area"></fieldset>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
            {/* <Footer /> */}




        </>

    )
}
