import React, { useEffect, useState } from 'react'
import services from './apiServices'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export default function Welcome() {

  const [valuationdata, setvaluationdata] = useState({})
  const [const_cost, setconst_cost] = useState([])
  const [uname, setuname] = useState()
  const [calculatedata, setcalculatedata] = useState({
    UOM: '',
    Property_type: '',
    Area_type: '',
    area_of_selected_type: '',
    total_prop_area: '',
    prop_height: '',
    floor_no: '',
    build_age: '',
    opened_terrace: '0',
    closed_terrace: '0',
    open_actual_parking_area: '0',
    closed_actual_parking_area: '0',
    shop_loc: '',
    at_basement: 'N',
    prop_at_Mezzanine: 'N',
    prop_without_lift: '',
    rera_reg: 'Y',
    prop_gifted: '',
    sale: '',
    land_value: '',
    flat_value: '',
  })

  useEffect(() => {
    const fetch = async () => {
      const data = await services.getcalvaluationdata()
      if (data !== false) {
        // console.log(data)
        setvaluationdata(data)
      }
      else {
        setuname('')
      }
    }
    fetch()
  }, [])

  if (uname === '') {
    localStorage.clear()
    window.location.replace('/')
  }
  else {

    const handlechange = (e) => {
      setcalculatedata({ ...calculatedata, [e.target.name]: e.target.value })
    }

    // console.log(valuationdata.uom ? valuationdata.uom[0] : null)
    // console.log(valuationdata.uom ? valuationdata.uom.map((da, index) => da.ATTRIB_NAME) : null)
    // console.log(valuationdata.uno.map((da,index)=>(valuationdata[da])))

    const handleSubmit = async (e) => {
      e.preventDefault()
      const res = await services.sendcalculatedata(calculatedata)
      if (res == false) {
        alert("You do not have enough recharge to avail this functionality")
        window.location.reload(false)
      } else {
        setconst_cost(res)
      }

    }
    return (
      <>
        <div className='full'>
          <div className="heading">
            <h1 >Valuation</h1>
            <span style={{ color: "#FFBB05" }}>Welcome To Property Valuation Calculator rdbs</span>
            <p>&nbsp;</p>
          </div>
          <div className="cd-full-width first-slide">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="content first-content">

                    <h4>Property Information rdbs</h4>

                    <form id="valuation_form" action="" method="post">
                      <div className="row">
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Unit of Measure <span className="txt_reqrd">*</span></label>
                            {/* <select className="form-control" name="UOM" onChange={handlechange} value={calculatedata.UOM} id="obj_sele_area_typ">
                            <option value="0">Select Option</option>
                            {valuationdata.uom ? valuationdata.uom.map((da, index) => (
                              <option value={da.ATTRIB_CD}>{da.ATTRIB_NAME}</option>
                            )) : null}
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              required
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Option"
                              onChange={handlechange}
                              value={calculatedata.UOM}
                              color="warning"
                              size='small'
                              name='UOM'
                            >
                              {valuationdata.uom ? valuationdata.uom.map((da, index) => (
                                <MenuItem key={da.ATTRIB_CD} value={da.ATTRIB_CD}>
                                  {da.ATTRIB_NAME}
                                </MenuItem>
                              )) : null}
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Property Type <span className="txt_reqrd">*</span></label>
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Property Type"
                              onChange={handlechange}
                              required
                              value={calculatedata.Property_type}
                              color="warning"
                              size='small'
                              name='Property_type'
                            >
                              {valuationdata.proptyp ? valuationdata.proptyp.map((da, index) => (
                                <MenuItem key={da.ATTRIB_CD} value={da.ATTRIB_CD}>
                                  {da.ATTRIB_NAME}
                                </MenuItem>
                              )) : null}
                            </TextField>
                            {/* <select className="form-control" name="Property_type" onChange={handlechange} value={calculatedata.Property_type} id="obj_sele_area_typ">
                            <option value="0">Select Option</option>
                            {valuationdata.proptyp ? valuationdata.proptyp.map((da, index) => (
                              <option value={da.ATTRIB_CD}>{da.ATTRIB_NAME}</option>
                            )) : null}
                          </select> */}
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Area Type <span className="txt_reqrd">*</span></label>
                            {/* <select className="form-control" name="Area_type" onChange={handlechange} value={calculatedata.Area_type} id="obj_sele_area_typ"  >
                            <option value="0">Select Option</option>
                            {valuationdata.areaTyp ? valuationdata.areaTyp.map((da, index) => (
                              <option value={da.ATTRIB_CD}>{da.ATTRIB_NAME}</option>
                            )) : null}
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              required
                              style={{ width: '100%' }}
                              label="Select Property Type"
                              onChange={handlechange}
                              value={calculatedata.Area_type}
                              color="warning"
                              size='small'
                              name='Area_type'
                            >
                              {valuationdata.uom ? valuationdata.areaTyp.map((da, index) => (
                                <MenuItem key={da.ATTRIB_CD} value={da.ATTRIB_CD}>
                                  {da.ATTRIB_NAME}
                                </MenuItem>
                              )) : null}
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Provide Area of Selected Type <span className="txt_reqrd">*</span></label>
                            <TextField type='number' required style={{ width: '100%' }} id="outlined-basic" name="area_of_selected_type" onChange={handlechange} value={calculatedata.area_of_selected_type} label="Area" variant="outlined" placeholder="Total Area of Selected Area Type" size='small' color='warning' />

                            {/* <input name="area_of_selected_type" onChange={handlechange} value={calculatedata.area_of_selected_type} type="number" className="form-control" id="email" placeholder="Total Area of Selected Area Type" required="" /> */}
                          </fieldset>
                        </div>
                      </div>
                      {/* Row 1 */}
                      <div className="row">
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Total Property Area <span className="txt_reqrd">*</span></label>
                            <TextField type='number' required style={{ width: '100%' }} id="outlined-basic" name="total_prop_area" onChange={handlechange} value={calculatedata.total_prop_area} label="Total Area" variant="outlined" placeholder="Total Area of Selected Area Type" size='small' color='warning' />

                            {/* <input name="total_prop_area" onChange={handlechange} value={calculatedata.total_prop_area} type="number" className="form-control" id="email" placeholder="Total Property Type Area" required="" /> */}
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Property Height</label>
                            {/* <select className="form-control" name="prop_height" onChange={handlechange} value={calculatedata.prop_height} id="obj_sele_area_typ">
                            <option value="0">Select Option</option>
                            {valuationdata.PropInrHight ? valuationdata.PropInrHight.map((da, index) => (
                              <option value={da.ATTRIB_CD}>{da.ATTRIB_NAME}</option>
                            )) : null}
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              required
                              style={{ width: '100%' }}
                              label="Select Property Height"
                              onChange={handlechange}
                              value={calculatedata.prop_height}
                              color="warning"
                              size='small'
                              name='prop_height'
                              disabled={calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'}
                            >
                              {valuationdata.PropInrHight ? valuationdata.PropInrHight.map((da, index) => (
                                <MenuItem key={da.ATTRIB_CD} value={da.ATTRIB_CD}>
                                  {da.ATTRIB_NAME}
                                </MenuItem>
                              )) : null}
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Floor No.</label>
                            {/* <select className="form-control" name="floor_no" onChange={handlechange} value={calculatedata.floor_no} id="obj_sele_flor_no">
                            <option value="0">Select Option</option>
                            {valuationdata.Prop_Val_Param_Flor ? valuationdata.Prop_Val_Param_Flor.map((da, index) => (
                              <option value={da.ATTRIB_CD}>{da.ATTRIB_NAME}</option>
                            )) : null}
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Floor Number"
                              onChange={handlechange}
                              value={calculatedata.floor_no}
                              color="warning"
                              size='small'
                              name='floor_no'
                              disabled={calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'}
                            >
                              {valuationdata.Prop_Val_Param_Flor ? valuationdata.Prop_Val_Param_Flor.map((da, index) => (
                                <MenuItem key={da.ATTRIB_CD} value={da.ATTRIB_CD}>
                                  {da.ATTRIB_NAME}
                                </MenuItem>
                              )) : null}
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Building Age</label>
                            <TextField required style={{ width: '100%' }} id="outlined-basic" name="build_age" disabled={calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'} onChange={handlechange} value={calculatedata.build_age} label="Age" variant="outlined" placeholder="Total Age of the building" size='small' color='warning' />

                            {/* <input name="build_age" onChange={handlechange} value={calculatedata.build_age} type="number" className="form-control" id="email" placeholder="Building Age" required="" /> */}
                          </fieldset>
                        </div>
                      </div>
                      {/* Row2 */}

                      <div className="row">
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Open Terrace/Balcony Area</label>
                            {/* <input name="opened_terrace" onChange={handlechange} value={calculatedata.opened_terrace} type="number" className="form-control" id="name" placeholder="Open Terrace Area" required="" /> */}
                            <TextField style={{ width: '100%' }} id="outlined-basic" name="opened_terrace" onChange={handlechange} value={calculatedata.opened_terrace} disabled={calculatedata.Property_type == 'Shop' || calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'} label="Open Terrace/Balcony Area" variant="outlined" placeholder="Enter Terrace/Balcony area" size='small' color='warning' />

                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Closed Terrace/Balcony Area</label>
                            {/* <input name="closed_terrace" onChange={handlechange} value={calculatedata.closed_terrace} type="number" className="form-control" id="email" placeholder="Closed Terrace Area" required="" /> */}
                            <TextField style={{ width: '100%' }} id="outlined-basic" name="closed_terrace" onChange={handlechange} value={calculatedata.closed_terrace} type='number' disabled={calculatedata.Property_type == 'Shop' || calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'} label="Closed Terrace/Balcony Area" variant="outlined" placeholder="Enter Terrace/Balcony area" size='small' color='warning' />

                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Open Actual Parking Area</label>
                            {/* <input name="open_actual_parking_area" onChange={handlechange} value={calculatedata.open_actual_parking_area} type="number" className="form-control" id="email" placeholder="Open Parking Area" required="" /> */}
                            <TextField style={{ width: '100%' }} id="outlined-basic" name="open_actual_parking_area" onChange={handlechange} value={calculatedata.open_actual_parking_area} disabled={calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'} type='number' label="Parking Area" variant="outlined" placeholder="Enter Parking area" size='small' color='warning' />

                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Covered Actual Parking Area</label>
                            {/* <input name="closed_actual_parking_area" onChange={handlechange} value={calculatedata.closed_actual_parking_area} type="number" className="form-control" id="email" placeholder="Covered Parking Area" required="" /> */}
                            <TextField style={{ width: '100%' }} disabled={calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'} id="outlined-basic" name="closed_actual_parking_area" onChange={handlechange} value={calculatedata.closed_actual_parking_area} type='number' label="Parking Area" variant="outlined" placeholder="Enter Parking area" size='small' color='warning' />

                          </fieldset>
                        </div>
                      </div>
                      {/* Row3 */}

                      <div className="row">
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">If Shop, Shop Location</label>
                            {/* <select className="form-control" name="shop_loc" onChange={handlechange} value={calculatedata.shop_loc} id="is_gift">
                            <option value="0">Select Option</option>
                            {valuationdata.Prop_Val_Param_Shop_Pos ? valuationdata.Prop_Val_Param_Shop_Pos.map((da, index) => (
                              <option value={da.ATTRIB_CD}>{da.ATTRIB_NAME}</option>
                            )) : null}
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Shop Location"
                              onChange={handlechange}
                              value={calculatedata.shop_loc}
                              color="warning"
                              size='small'
                              name='shop_loc'
                              disabled={calculatedata.Property_type == 'Resdtl' || calculatedata.Property_type == 'RoHus' || calculatedata.Property_type == 'Offc' || calculatedata.Property_type == 'Cmrstl' || calculatedata.Property_type == 'NonAgri' || calculatedata.Property_type == 'FrmHus' || calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd' || calculatedata.Property_type == 'MIDC'}
                            >
                              {valuationdata.Prop_Val_Param_Shop_Pos ? valuationdata.Prop_Val_Param_Shop_Pos.map((da, index) => (
                                <MenuItem key={da.ATTRIB_CD} value={da.ATTRIB_CD}>
                                  {da.ATTRIB_NAME}
                                </MenuItem>
                              )) : null}
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Is Shop/Godown at Basement</label>
                            {/* <select className="form-control" name="at_basement" onChange={handlechange} value={calculatedata.at_basement} id="is_gift">
                            <option value="0">Select Option</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="At Basement"
                              onChange={handlechange}
                              value={calculatedata.at_basement}
                              color="warning"
                              size='small'
                              name='at_basement'
                              disabled={calculatedata.Property_type == 'Resdtl' || calculatedata.Property_type == 'RoHus' || calculatedata.Property_type == 'Offc' || calculatedata.Property_type == 'Cmrstl' || calculatedata.Property_type == 'NonAgri' || calculatedata.Property_type == 'FrmHus' || calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd' || calculatedata.Property_type == 'MIDC'}
                            >
                              <MenuItem value='Y'>Yes</MenuItem>
                              <MenuItem value='N'>No</MenuItem>
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Is Property At Mezzanine Floor?</label>
                            {/* <select className="form-control" name="prop_at_Mezzanine" onChange={handlechange} value={calculatedata.prop_at_Mezzanine} id="is_gift">
                            <option value="0">Select Option</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Option"
                              onChange={handlechange}
                              value={calculatedata.prop_at_Mezzanine}
                              color="warning"
                              size='small'
                              name='prop_at_Mezzanine'
                            // disabled={calculatedata.Property_type=='Resdtl'|| calculatedata.Property_type=='NonAgri' ||calculatedata.Property_type=='FrmHus'||calculatedata.Property_type=='Agri'||calculatedata.Property_type=='OpLnd'}
                            >
                              <MenuItem value='Y'>Yes</MenuItem>
                              <MenuItem value='N'>No</MenuItem>
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">If Property Without Lift </label>
                            {/* <select className="form-control" name="prop_without_lift" onChange={handlechange} value={calculatedata.prop_without_lift} id="is_gift">
                            <option value="0">Select Option</option>
                            {valuationdata.Prop_Val_Param_No_Lift ? valuationdata.Prop_Val_Param_No_Lift.map((da, index) => (
                              <option value={da.ATTRIB_CD}>{da.ATTRIB_NAME}</option>
                            )) : null}
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Option"
                              onChange={handlechange}
                              value={calculatedata.prop_without_lift}
                              color="warning"
                              size='small'
                              name='prop_without_lift'
                              disabled={calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'}
                            >
                              {valuationdata.Prop_Val_Param_No_Lift ? valuationdata.Prop_Val_Param_No_Lift.map((da, index) => (
                                <MenuItem key={da.ATTRIB_CD} value={da.ATTRIB_CD}>
                                  {da.ATTRIB_NAME}
                                </MenuItem>
                              )) : null}
                            </TextField>
                          </fieldset>
                        </div>
                      </div>
                      {/* Row4 */}

                      <div className="row">
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Is under RERA Registered?</label>
                            {/* <select className="form-control" name="rera_reg" onChange={handlechange} value={calculatedata.rera_reg} id="is_gift">
                            <option value="N" selected="selected">No</option>
                            <option value="Y">Yes</option>
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Option"
                              onChange={handlechange}
                              value={calculatedata.rera_reg}
                              color="warning"
                              size='small'
                              name='rera_reg'
                              disabled={calculatedata.Property_type == 'Agri' || calculatedata.Property_type == 'OpLnd'}
                            >
                              <MenuItem value='Y'>Yes</MenuItem>
                              <MenuItem value='N' selected>No</MenuItem>
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Is Property Gifted</label>
                            {/* <select className="form-control" name="prop_gifted" onChange={handlechange} value={calculatedata.prop_gifted} id="is_gift">
                            <option value="0">Select Option</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Option"
                              onChange={handlechange}
                              value={calculatedata.prop_gifted}
                              color="warning"
                              size='small'
                              name='prop_gifted'
                            >
                              <MenuItem value='Y'>Yes</MenuItem>
                              <MenuItem value='N'>No</MenuItem>
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">1st Sale / 2nd Sale</label>
                            {/* <select className="form-control" name="sale" onChange={handlechange} value={calculatedata.sale} id="is_gift">
                            <option value="0">Select Option</option>
                            <option value="1">1st Sale</option>
                            <option value="2">2nd Sale</option>
                          </select> */}
                            <TextField
                              id="outlined-select-currency"
                              select
                              className='size'
                              style={{ width: '100%' }}
                              label="Select Option"
                              onChange={handlechange}
                              value={calculatedata.sale}
                              color="warning"
                              size='small'
                              name='sale'
                            >
                              <MenuItem value='1'>1st Sale</MenuItem>
                              <MenuItem value='2'>2nd Sale</MenuItem>
                            </TextField>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl"></label>
                          </fieldset>
                        </div>
                      </div>
                      {/* Row5 */}

                      <div className="row">
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Land Value <br />(Ready Reckoner Value) <span className="txt_reqrd">*</span></label>
                            {/* <input name="land_value" onChange={handlechange} value={calculatedata.land_value} type="number" className="form-control" id="email" placeholder="Land Value (Ready Reckoner)" required="" /> */}
                            <TextField style={{ width: '100%' }} id="outlined-basic" name="land_value" onChange={handlechange} value={calculatedata.land_value} type='number' label="Land Value" variant="outlined" placeholder="Enter Value" size='small' color='warning' />

                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Building/Flat Value <br />(Ready Reckoner Value) <span className="txt_reqrd">*</span></label>
                            {/* <input name="flat_value" onChange={handlechange} value={calculatedata.flat_value} type="number" className="form-control" id="email" placeholder="Residence/Commercial Value" required="" /> */}
                            <TextField style={{ width: '100%' }} required id="outlined-basic" name="flat_value" onChange={handlechange} value={calculatedata.flat_value} type='number' label="Flat Value" variant="outlined" placeholder="Enter Value" size='small' color='warning' />

                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl"><br />Construction Cost<span className="txt_reqrd"></span></label>
                            {/* <input name="construction_cost" onChange={handlechange} value={calculatedata.construction_cost} disabled="true" type="number" className="form-control" id="email" placeholder="Construction Cost" required="" /> */}
                            <TextField style={{ width: '100%' }} required id="outlined-basic" name="construction_cost" onChange={handlechange} value={const_cost} type='number' label="Construction Cost" disabled="true" variant="outlined" placeholder="Total Cost" size='small' color='warning' />

                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl"></label>
                          </fieldset>
                        </div>
                      </div>
                      {/* Row6 */}

                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area">
                          <label><span className="rdb_span">Note / Message / Information for above inputs and calculation. Note / Message / Information for above inputs and calculation. Note / Message / Information for above inputs and calculation. Note / Message / Information for above inputs and calculation. Note / Message / Information for above inputs and calculation. Note / Message / Information for above inputs and calculation. </span></label>
                        </fieldset>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area"></fieldset>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area">
                          <input type="hidden" name="sele_btn" value="" />
                          <input type="hidden" name="hdn_login_id" value="" />
                          <input type="hidden" name="hdn_nxt_pg" value="" />
                          <input type="hidden" name="hdn_cncl_pg" value="" />
                          <input type="hidden" name="hdn_bv_calcy_trn_pg" value="" />
                          <input type="hidden" name="hdn_bv_trn_frm" value="" />
                          <input type="hidden" name="hdn_pg_web_hom" value="" />
                          <input type="hidden" name="hdn_db_hndl_fns_lib" value="" />
                          <input type="hidden" name="hdn_db_sql_dat" value="" />
                          <input type="hidden" name="hdn_db_get_sql_lib" value="" />
                          <input type="hidden" name="hdn_val_fns_lib" value="" />
                          <button type="submit" id="form-submit" onClick={handleSubmit} className="btn"><b>Calculate</b></button>
                        </fieldset>
                      </div>


                      <div className="col-md-12">
                        <fieldset className="fld_area"></fieldset>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="row rslt_are">
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="rslt_fld_lbl">Total Cost: </label>
                          </fieldset>
                        </div>
                        <div className="col-md-3">
                          <fieldset className="fld_area">
                            <label className="fld_lbl rslt_fld_val">0.00</label>
                          </fieldset>
                        </div>
                        <div className="col-md-3">

                        </div>
                        <div className="col-md-3">

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area"></fieldset>
                      </div>

                    </form>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </>
    )
  }
}
