import React from 'react'
import services from './apiServices'
import { useEffect, useState } from 'react'



export default function Usage() {

    const [usedata, setusedata] = useState([])
    const [bal, setbal] = useState()
    const [uname, setuname] = useState()

    useEffect(() => {
        const fetch = async () => {
            const data = await services.getusagedata()
            //   console.log(data[0].USR_NTT_KY)
            if (data !== false) {
                setusedata(data.usagedisp)
                setbal(data.balance)
                setuname(data.loginid)
            }
            else {
                setbal('')
                setuname('')
                console.log(data)
            }
        }
        fetch()
    }, [])
    if (uname === '') {
        localStorage.clear()
        window.location.replace('/')
    }
    else {

        console.log(usedata)

        //   for(i=1,i<=usedata.length,i++){
        //     console.log(usedata[i].USR_NTT_KY)
        //   }

        // for (let i = 0; i < usedata.length; i++) {
        //     console.log(usedata[i].USR_NTT_KY)

        // }

        function formatdate(datestring) {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const date = new Date(datestring)
            const day = date.getDay()
            const month = months[date.getMonth()]
            const year = date.getFullYear()

            const formattedday = day < 10 ? '0' + day : day

            return `${formattedday}-${month}-${year}`
        }


        return (
            <>

                <div className='full'>
                    <div className="heading">
                        <h1 >Usage Tracking</h1>
                        <span style={{ color: "#FFBB05" }}>Check your recent Transactions</span><br />&nbsp;
                    </div>
                    <div className="cd-half-width third-slide">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="content third-content">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="right-feature-text">
                                                    <h4 className='adjustedtext'>User Name: <em>{uname}</em></h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset className="fld_area">
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="">
                                                    <h4>Transaction List</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset className="fld_area">
                                                    <label className="fld_lbl">Total Balance Amount : {bal}</label>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-1">
                                <label>Sr. No.</label>
                            </div>
                            <div className="col-md-2">
                                <label>Date</label>
                            </div>
                            <div className="col-md-2">
                                <label>Carpet Area</label>
                            </div>
                            <div className="col-md-2">
                                <label>Built-up Area</label>
                            </div>
                            <div className="col-md-1">
                                <label>Floor No.</label>
                            </div>
                            <div className="col-md-1">
                                <label>Building Age</label>
                            </div>
                            <div className="col-md-2">
                                <label>Total Valuation</label>
                            </div>
                            <div className="col-md-1">
                                <label>Stamp Duty</label>
                            </div> */}
                                            <table className="table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        {/* <th scope="col">#</th> */}
                                                        <th scope="col" className='custtext'>Sr. No.</th>
                                                        <th scope="col" className='custtext'>Date</th>
                                                        <th scope="col" className='custtext'>Login Id</th>
                                                        <th scope="col" className='custtext'>Builtup Area</th>
                                                        <th scope="col" className='custtext'>Total Valuation</th>
                                                    </tr> 
                                                </thead> 
                                                <tbody> 
                                                    {usedata.map((id, index) => (
                                                        <tr>
                                                            <td className='custtext'>{index + 1}</td>
                                                            <td className='custtext'>{formatdate(usedata[index].TRN_DT_TM)}</td>
                                                            <td className='custtext'>{usedata[index].USR_LOGIN_ID}</td>
                                                            <td className='custtext'>{usedata[index].BUILT_UP}</td>
                                                            <td className='custtext'>{usedata[index].TRN_CAL_COST}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className="row">
                            <div className="col-md-12">
                              <hr />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                              <fieldset className="fld_area"></fieldset>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1">
                                <label>1</label>
                            </div>
                            <div className="col-md-2">
                                <label>11-MMM-2022</label>
                            </div>
                            <div className="col-md-2">
                                <label>800</label>
                            </div>
                            <div className="col-md-2">
                                <label>710</label>
                            </div>
                            <div className="col-md-1">
                                <label>4</label>
                            </div>
                            <div className="col-md-1">
                                <label>3</label>
                            </div>
                            <div className="col-md-2">
                                <label>9,99,99,999.99</label>
                            </div>
                            <div className="col-md-1">
                                <label>99,999.99</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1">
                                <label>2</label>
                            </div>
                            <div className="col-md-2">
                                <label>22-MMM-2022</label>
                            </div>
                            <div className="col-md-2">
                                <label>800</label>
                            </div>
                            <div className="col-md-2">
                                <label>710</label>
                            </div>
                            <div className="col-md-1">
                                <label>4</label>
                            </div>
                            <div className="col-md-1">
                                <label>3</label>
                            </div>
                            <div className="col-md-2">
                                <label>9,99,99,999.99</label>
                            </div>
                            <div className="col-md-1">
                                <label>99,999.99</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                              <hr />
                            </div>
                        </div> */}

                                        <div className="primary-button">
                                            <a href="/">Print</a>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
