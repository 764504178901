import React, { useEffect, useState } from 'react'
import home from '../Img/home-icon.png'
import home2 from '../Img/home-icon_rvrs.png'
import about from '../Img/about-icon.png'
import about2 from '../Img/about-icon_rvrs.png'
import featured from '../Img/featured-icon.png'
import featured2 from '../Img/featured-icon_rvrs.png'
import contact from '../Img/contact-icon.png'
import contact2 from '../Img/contact-icon_rvrs.png'
import Recharge from '../Img/rdb_money.png'
import Recharge2 from '../Img/rdb_money_rvrs.png'
import Logout from '../Img/logout.png'
import Logout2 from '../Img/logout_rvrs.png'
import '../Css/rdbs.css'
// import '../Js/rdbs2.js'
import '../Css/rdbsmedia.css'


import { Link } from "react-router-dom";


export default function Navbar(props) {
  function changeTab(tab) {
    setactivetab(tab)
  }

  // function starttimer(duration, display) {
  //   let timer = duration, min, sec

  //   setInterval(function () {
  //     min = parseInt(timer / 60, 10)
  //     sec = parseInt(timer % 60, 10)

  //     min = min < 10 ? '0' + min : min
  //     sec = sec < 10 ? '0' + sec : sec

  //     display.textContent=min+":"+sec

  //     // console.log(sec)

  //     if(--timer<0){
  //       localStorage.clear()
  //       window.location.replace('/')
  //     }
  //   }, 1000)


  // }

  const imgs = [home, about, featured, Recharge, contact, Logout]
  const imgsr = [home2, about2, featured2, Recharge2, contact2, Logout2]


  const usertype = props.typeofuser
  const [activetab, setactivetab] = useState()
  const [imgsrc, setsrc] = useState([])

  useEffect(() => {

    const changeimg = () => {
      const media = window.matchMedia('(max-width:767px)')
      console.log(media.matches, 'hasdgashf')
      media.matches ? setsrc(imgsr) : setsrc(imgs)

    }

    // const sessiont=()=>{
    //   let time=60*10
    //   let disp=document.querySelector('#timer')
    //   starttimer(time,disp)
    // }

    // sessiont()
    changeimg()

  }, [])



  console.log(imgsrc)
  // console.log(home2)
  // console.log(changeimg())
  return (
    usertype == 'O' ? (
      <>
        <div id="timer"></div>
        <div className="cd-slider-nav navback head">
          <nav>
            <span className="cd-marker item-1"></span>
            <ul>
              <li className={`selected`} ><Link to="/"><div className={`image-icon ${activetab === 0 ? 'active' : ''}`}><img src={imgsrc[0]} onClick={() => changeTab(0)} /></div><h6>Valuation</h6></Link></li>
              <li className={`selected`}><Link to="/approval"><div className={`image-icon  ${activetab === 1 ? 'active' : ''}`}><img src={imgsrc[1]} onClick={() => changeTab(1)} /></div><h6>Users</h6></Link></li>
              <li className={`selected`}><Link to="/usage"><div className={`image-icon  ${activetab === 2 ? 'active' : ''}`}><img src={imgsrc[2]} onClick={() => changeTab(2)} /></div><h6>Usage</h6></Link></li>
              <li className={`selected`}><Link to="/recharge"><div className={`image-icon  ${activetab === 3 ? 'active' : ''}`}><img src={imgsrc[3]} onClick={() => changeTab(3)} /></div><h6>Recharge</h6></Link></li>
              <li className={`selected`}><Link to="/aboutus"><div className={`image-icon  ${activetab === 4 ? 'active' : ''}`}><img src={imgsrc[4]} onClick={() => changeTab(4)} /></div><h6>About Us</h6></Link></li>
              <li className={`selected`}><Link to="/logout"><div className={`image-icon  ${activetab === 5 ? 'active' : ''}`}><img src={imgsrc[5]} onClick={() => changeTab(5)} /></div><h6>Logout</h6></Link></li>
            </ul>
          </nav>
        </div>
      </>
    ) : (
      <>
        <div className="cd-slider-nav navback head">
          <nav>
            <span className="cd-marker item-1"></span>
            <ul>
              <li className={`selected `}><Link to="/"><div className={`image-icon ${activetab === 0 ? 'active' : ''}`}><img src={imgsrc[0]} onClick={() => changeTab(0)} /></div><h6>Valuation</h6></Link></li>
              <li className={`selected `}><Link to="/usage"><div className={`image-icon ${activetab === 1 ? 'active' : ''}`}><img src={imgsrc[2]} onClick={() => changeTab(1)} /></div><h6>Usage</h6></Link></li>
              <li className={`selected `}><Link to="/recharge"><div className={`image-icon ${activetab === 2 ? 'active' : ''}`}><img src={imgsrc[3]} onClick={() => changeTab(2)} /></div><h6>Recharge</h6></Link></li>
              <li className={`selected `}><Link to="/aboutus"><div className={`image-icon ${activetab === 3 ? 'active' : ''}`}><img src={imgsrc[4]} onClick={() => changeTab(3)} /></div><h6>About Us</h6></Link></li>
              <li className={`selected `}><Link to="/logout"><div className={`image-icon ${activetab === 4 ? 'active' : ''}`}><img src={imgsrc[5]} onClick={() => changeTab(4)} /></div><h6>Logout</h6></Link></li>

            </ul>
          </nav>
        </div>
      </>
    )
  )
}
