import React, { useState, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import {

  // Switch,
  Link
} from "react-router-dom";
import services from './apiServices';

export default function Users() {

  let [regdata, setregData] = useState({
    org_name: '',
    contact_per_name: '',
    org_ini: '',
    u_type: '',
    u_title: '',
    u_bldgrp: '',
    u_gender: '',
    org_add1: '',
    org_add2: '',
    org_add3: '',
    a_type: '',
    city: '',
    pin: '',
    state: '',
    contact_num: '',
    email: '',
    loginid: '',
    password: ''
  })

  const [bldgrps, setbldgrps] = useState([])

  // const getfirstletters=(sentence)=>{
  //   const words=sentence.split(" ")
  //   const fletter=words.map(word=>word[0])
  //   return fletter.join("")
  // }

  // const getfirstletter=(word)=>{
  //   const first=word[0]
  //   return first
  // }

  useEffect(() => {
    const fetchbld = async () => {
      const blds = await services.getallregdata()
      setbldgrps(blds)
    }

    fetchbld()
  }, [])


  const handleChange = (e) => {
    setregData({ ...regdata, [e.target.name]: e.target.value })

  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    // orgini=getfirstletter(regdata.u_type)
    // setregData(regdata.e_type=orgini)
    const res = await services.sendRegData(regdata)
    if (res == false) {
      alert("The Company Initial already exist")
      window.location.reload(false)
    }
    else {
      alert("You have been registered Successfully...")
      window.location.reload(false)
    }

  }

  return (
    <>
      <div className='cd-hero-reg'>
        <div className="regtop">
          <div className="heading ">
            <h1 >User Registration</h1>
            <span >We produce future product</span> <br />&nbsp;
          </div>
          <div className="cd-half-width second-slide">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="content second-content">
                    <h4>User Information</h4>
                    <form id="contact" action="" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Organisation Name</label>
                            <input name="org_name" type="text" value={regdata.org_name} onChange={handleChange} className="form-control" id="name" placeholder="Organisation Name" required="" />
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Organization Initials</label>
                            <input name="org_ini" type="text" value={regdata.org_ini} onChange={handleChange} className="form-control" id="ini" placeholder="Organization Initials" required="" />
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Select User Title</label>
                            <select className="form-control" onChange={handleChange} value={regdata.u_title} name="u_title" id="login_org_id">
                              <option value="0">Select Option</option>
                              <option value="Mr">Mr</option>
                              <option value="Mrs">Mrs</option>
                            </select>
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Contact Person Name</label>
                            <input name="contact_per_name" type="text" value={regdata.contact_per_name} onChange={handleChange} className="form-control" id="email" placeholder="Contact Person Name" required="" />
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Select Blood Group</label>
                            <select className="form-control" onChange={handleChange} value={regdata.u_bldgrp} name="u_bldgrp" id="login_org_id">
                              <option value="0">Select Option</option>

                              {bldgrps.map(b => (
                                <option value={b.ATTRIB_CD}>{b.ATTRIB_CD}</option>
                              ))}


                            </select>
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Gender</label>
                            <select className="form-control" onChange={handleChange} value={regdata.u_gender} name="u_gender" id="login_org_id">
                              <option value="0">Select Option</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Select User Type</label>
                            <select className="form-control" onChange={handleChange} value={regdata.u_type} name="u_type" id="login_org_id">
                              <option value="0">Select Option</option>
                              <option value="Employee">Employee</option>
                              <option value="Supervisor">Supervisor</option>
                              <option value="Maker">Maker</option>
                              <option value="Checker">Checker</option>
                            </select>
                          </fieldset>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Organisation Address</label>
                            <input name="org_add1" type="text" value={regdata.org_add1} onChange={handleChange} className="form-control" id="name" placeholder="Address Line 1" required="" /><br />
                            <input name="org_add2" type="text" value={regdata.org_add2} onChange={handleChange} className="form-control" id="name" placeholder="Address Line 2" required="" /><br />
                            <input name="org_add3" type="text" value={regdata.org_add3} onChange={handleChange} className="form-control" id="name" placeholder="Address Line 3" required="" /><br />
                            <label className="fld_lbl">Address Type</label>
                            <select className="form-control" onChange={handleChange} value={regdata.a_type} name="a_type" id="login_org_id">
                              <option value="0">Select Option</option>
                              <option value="OFF_ADD">Office Address</option>
                              <option value="HOME_ADD">Home Address</option>
                            </select>
                          </fieldset>
                        </div>
                        {/* <div className="col-md-6">
                        <fieldset className="fld_area">
                        
                        </fieldset>
                      </div> */}
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">City / Pin / State </label>
                            <input name="city" type="text" value={regdata.city} onChange={handleChange} className="form-control" id="email" placeholder="Address City" required="" /><br />
                            <input name="pin" type="number" value={regdata.pin} onChange={handleChange} className="form-control" id="email" placeholder="Address City Pincode" required="" /><br />
                            <input name="state" type="text" value={regdata.state} onChange={handleChange} className="form-control" id="email" placeholder="Address State" required="" />

                          </fieldset>
                        </div>
                      </div>
                      <hr />

                      <div className="row">
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Contact Number</label>
                            <input name="contact_num" type="number" value={regdata.contact_num} onChange={handleChange} className="form-control" id="name" placeholder="Phone Number" required="" />
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Email ID</label>
                            <input name="email" type="text" value={regdata.email} onChange={handleChange} className="form-control" id="email" placeholder="Email ID" required="" />
                          </fieldset>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Login ID</label>
                            <input name="loginid" type="text" value={regdata.loginid} onChange={handleChange} className="form-control" id="name" placeholder="Login ID" required="" />
                          </fieldset>
                        </div>
                        <div className="col-md-6">
                          <fieldset className="fld_area">
                            <label className="fld_lbl">Password</label>
                            <input name="password" type="password" value={regdata.password} onChange={handleChange} className="form-control" id="email" placeholder="Password" required="" />
                          </fieldset>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area"></fieldset>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area">
                          <Link type="" to="/" className="btn btn-style" >&nbsp;&nbsp;<b>Back to Login</b>&nbsp;&nbsp;</Link>
                          <button type="submit" id="form-submit" className="btn">&nbsp;&nbsp;&nbsp;&nbsp;<b>Save</b>&nbsp;&nbsp;&nbsp;&nbsp;</button>

                        </fieldset>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <hr />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <fieldset className="fld_area"></fieldset>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
