import axios from 'axios'

const baseURL = 'https://valprobc.samirantours.com';

const services = {
    getassociateidlogin: async () => {
        try {
            // const res = await axios.get(`${baseURL}/login`);
            const res = await axios.get(`/api/login`);
            return res.data
        }
        catch (error) {
            throw new Error('Failed to pull data')
        }
    },

    sendLoginData: async (creds) => {
        try {
            // const res=await axios.post(`${baseURL}/login`, creds,{
            //     headers:{
            //         'Content-Type': 'application/json'
            //     }
            // })
            const res=await axios.post(`/api/login`, creds)
            return res.data
            

        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
        }
    },

    sendRegData: async (regd) => {
        try {
            // const res=await axios.post(`${baseURL}/register`, regd, {
            //     headers:{
            //         'Content-Type': 'application/json'
            //     }
            // })
            const res=await axios.post(`/api/register`, regd)
            return res.data
            

        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
        }
    },

    getallregdata:async()=>{
        try {
            // const res=await axios.get(`${baseURL}/register`)
            const res=await axios.get(`/api/register`)
            return res.data
        } catch (error) {
            throw new Error(`Failed to pull data ${error}`)
        }
    },

    getallnonapprovedusers:async()=>{
        try {
            // const res=await axios.get(`${baseURL}/approve`)
            const res=await axios.get(`/api/approve`)
            return res.data
        } catch (error) {
            throw new Error(`Failed to pull data ${error}`)
        }
    },
    sendApprovalData: async (data) => {
        try {
            // const res=await axios.post(`${baseURL}/approve`, data, {
            //     headers:{
            //         'Content-Type': 'application/json'
            //     }
            // })
            const res=await axios.post(`/api/approve`, data)
            return res.data
            

        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
        }
    },
    sendRejectionData: async (data) => {
        try {
            // const res=await axios.post(`${baseURL}/reject`, data ,{
            //     headers:{
            //         'Content-Type': 'application/json'
                    
            //     }
            // })
            const res=await axios.post(`/api/reject`, data)
            return res.data
            

        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
        }
    },
    getrechargesessiondata:async (data)=>{
        try {
            // const res=await axios.get(`${baseURL}/recharge`)
            const res=await axios.get(`/api/recharge`)
            return res.data
            
        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
            
        }
    },
    sendrechargedata:async(rechargedata)=>{
        try {
            // const res=await axios.post(`${baseURL}/recharge`,rechargedata, {
            //     headers:{
            //         'Content-Type': 'application/json'
            //     }
            // })
            const res=await axios.post(`/api/recharge`,rechargedata)
        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
            
        }
    },
    getcalvaluationdata:async()=>{
        try {
            // const res=await axios.get(`${baseURL}/calculate_val`)
            const res=await axios.get(`/api/calculate_val`)
            return res.data
        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)      
        }
    },
    sendcalculatedata:async(data)=>{
        
        try {
            // const r=await axios.post(`${baseURL}/calculate_val`,data , {
            //     headers:{
            //         'Content-Type': 'application/json'
            //     }
            // })
            const r=await axios.post(`/api/calculate_val`,data)
            return r.data
        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)   
        }
    },
    getcaptcha:async()=>{
        try {
            // const res=await axios.get(`${baseURL}/about`)
            const res=await axios.get(`/api/about`)
            return res.data
        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
        }
    },
    sendcaptchadata:async(data)=>{
        try {
            // const res=await axios.post(`${baseURL}/about`,data, {
            //     headers:{
            //         'Content-Type': 'application/json'
            //     }
            // })
            const res=await axios.post(`/api/about`,data)
            return res.data
        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
        }
    },
    
    getusagedata:async()=>{
        try {
            // const res=await axios.get(`${baseURL}/usage`)
            const res=await axios.get(`/api/usage`)
            return res.data
        } catch (error) {
            throw new Error(`Failed to send Data: ${error}`)
        }
    }
}

export default services;
