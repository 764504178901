import React from 'react'

export default function Header() {
  return (
    <>
        <div  className="cd-head-login">
            <div className="head">
                 {/* <h1  >Welcome To Property Valuation Calculator Application</h1> */}
                {/*<label>&nbsp;</label> */}
            </div>
    </div>
    </>
  )
}
