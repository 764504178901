import React, { useEffect, useState } from 'react';
import ra from '../Img/right-about-image.jpg';
import la from '../Img/left-about-image.jpg';
import services from './apiServices';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';


export default function AboutUs() {

  const [captcha, setcaptcha] = useState('')
  const [captchaimg, setcaptchaimg] = useState('')
  const [textdata, settextdata] = useState({
    name: '',
    email: '',
    message: '',
    captcha: ''
  })

  useEffect(() => {
    const generatecaptcha = async () => {


      const data = await services.getcaptcha()
      setcaptcha(data.text)
      setcaptchaimg(data.image)
    }
    generatecaptcha()
  }, [])


  const handleChange = (e) => {
    settextdata({ ...textdata, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await services.sendcaptchadata(textdata)
    if (res == true) {
      alert("Mail sent successfully")
      window.location.reload(false)
    }
    else {
      alert("Wrong Captcha")
      window.location.reload(false)
    }
  }

  // Text Area



  return (
    <>
      <div className='full'>
        <div className="heading">
          <h1 >About us</h1>
          <span style={{ color: "#FFBB05" }}>Get More Info About Our Organisation</span><br /> &nbsp;
        </div>
        <div className="cd-half-width fivth-slide">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="content fivth-content">

                  <div className="row">
                    <div className="col-md-7 left-image">
                      <img src={la} />
                    </div>
                    <div className="col-md-5">
                      <div className="right-about-text">
                        <h4>Who we are?</h4>
                        <p>Say Somthing about Who we are. Explain in Details the same. Say Somthing about Who we are. Explain in Details the same. Say Somthing about Who we are. Explain in Details the same. Say Somthing about Who we are. Explain in Details the same. Say Somthing about Who we are. Explain in Details the same. </p>
                        <div className="primary-button">

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-7">
                      <div className="left-about-text">
                        <h4>What we do?</h4>
                        <p>Say Somthing about What we do. Explain in Details the same. Say Somthing about What we do. Explain in Details the same. Say Somthing about What we do. Explain in Details the same. Say Somthing about What we do. Explain in Details the same. Say Somthing about What we do. Explain in Details the same. </p>
                        <div className="primary-button">

                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 right-image">
                      <img src={ra} />
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-12">
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <br />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3 className="text-center">Contact us</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <br />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="left-info">
                        <p><b>Name of the Organisation</b><br />Address Line 1,<br />Address Line 2,<br />Address Line 3,<br />Pune - 411 001.<br />
                          <br />Phone - 99999 99999<br />Email - my_email@domain.com<br />Website - www.domain.com
                        </p>

                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <form id="contact" >
                          <div className='row about-row'>

                            <div className="col-md-6 ">
                              <fieldset>
                                {/* <input name="name" value={textdata.value} onChange={handleChange} type="text" className="form-control" id="name" placeholder="Your Name" required /> */}
                                {/* <TextField
                                id="outlined-select-currency"
                                select
                                className='size'
                                style={{ width: '100%' }}
                                label="Your Name"
                                value={textdata.value}
                                onChange={handleChange}
                                color="warning"
                                size='small'
                                required
                                name='associateid'
                              ></TextField> */}
                                <TextField style={{ width: '100%' }} required id="outlined-basic" className='size' label="Your Name" variant="outlined" size='small' color='warning' name="name" value={textdata.name} onChange={handleChange} />

                              </fieldset>
                            </div>
                          </div>

                          <div className='row about-row'>

                            <div className="col-md-6">
                              <fieldset>
                                <TextField style={{ width: '100%' }} id="outlined-basic" className='size' label="Email" variant="outlined" size='small' color='warning' name="email" value={textdata.email} required onChange={handleChange} />
                                {/* <input name="email" value={textdata.email} onChange={handleChange} type="email" className="form-control" id="email" placeholder="Email" required /> */}
                              </fieldset>
                            </div>


                          </div>
                          <div className='row about-row'>

                            <div className="col-md-12">
                              <fieldset>
                                <TextField name="message" value={textdata.message} onChange={handleChange} id="outlined-basic" className='size' label="Message" variant="outlined" color='warning' placeholder="Message" required multiline
                                  rows={2}
                                  maxRows={4} />
                                {/* <Textarea */}
                              </fieldset>
                            </div>
                          </div>
                          <div className='row about-row'>
                            <div className="col-md-12">
                              <div className='row'>
                                <div className='col-md-6'>
                                  {/* {captchaimg}
                              <img src={captchaimg}/> */}
                                  <TextField style={{ width: '100%' }} name="captcha" value={textdata.captcha} onChange={handleChange} id="outlined-basic" className='size' label="Captcha" size='small' variant="outlined" color='warning' placeholder="Enter captcha" required></TextField>
                                  {/* <input style={{ height: '100%' }} name="captcha" value={textdata.captcha} onChange={handleChange} type='text' className="form-control" id="captcha" placeholder="Enter Captcha" required /> */}
                                </div>
                                <div className='col-md-6'>
                                  {captchaimg && <div dangerouslySetInnerHTML={{ __html: captchaimg }} />}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <fieldset>
                              <button type='submit' onClick={handleSubmit} className="btn">Send Message</button>
                            </fieldset>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}
