import React from 'react'
import Header from './Header'
import Footer from './Footer'
import {
    Link
} from "react-router-dom";

export default function Readme() {
    return (
        <div>
            <section className="cd-hero">


                <ul className="cd-hero-slider">


                    <li className="selected">
                        <div className="heading">
                            <h1>Property Valuation Calculator</h1>
                            <span>Welcome To Property Valuation Calculator Application</span>
                            <p>&nbsp;</p>
                        </div>
                        <div className="cd-full-width first-slide">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-1">
                                    </div>
                                    <div className="col-md-10">
                                        <div className="content login-content">
                                            <h4>Property Valuation Calculator</h4>

                                            <div className="row">
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                                <div className="col-md-10">
                                                    <p>The process of estimating the value of property is known as valuation. There are several methods of assessing the value of a property.</p>
                                                    <p>You could be a buyer, a seller, a lessor, or a lessee. In any case, knowing the current value of a property will help you fix costs and rates right.</p>
                                                    <p>Ready Reckoner which helps to calculate the true market value of immovable property, i.e. land, residential, commercial and industrial properties for any area defined and published by government of Maharashtra, in their publication namely Annual Statement Rates (ASR) the book is in Marathi known as Bazaar Mulyankan Takta.</p>
                                                    <p>The true market value of land property means the price which such property would fetch in the open market on the date of signing or execution of such instrument / documents or the consideration stated in the instrument / documents which ever is higher.</p>
                                                    <p>The Market Value is determined by ready reckoner (ASR) Annual Statement Rate value fixed and published every year on 31st December, under the Maharashtra Stamp Act, (59 of 1958) and the Maharashtra Stamp (Determination of True Market Value of Property) Rule, 1995.</p>
                                                    <p>With the help of “RECKONER” you have to locate the Division / Village with Zone / SubZone and C.S. / C.T.S. number etc. of your property, from the table you can find the rates of property such as Land, Residential, Office, Shop and Industrial Unit. Rates are per Square Meter (Built-Up-Area) or in Hectare.</p>
                                                    <p>We have created the “Valuation Calculator” website to help you. We have provided this tool to calculate correct valuation by accepting the various parameters as per the Reckoner. we explained in detail how to calculate the Market Value of the property etc.</p>
                                                </div>
                                                <div className="col-md-1">
                                                    &nbsp;
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <hr />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <fieldset className="fld_area">
                                                    <div className="primary-button">
                                                        <Link to="/users"><b>Register</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;

                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <hr />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

            </section>







        </div>

    )
}
